<template>
  <section
    id="form-section"
    v-editable="blok"
    :data-section-uid="blok._uid"
    :style="[backgroundColor]"
    :class="[paddingBottom, paddingTop]"
  >
    <div class="container container--section">
      <div class="form-wrapper">
        <StoryblokComponent
          v-for="childBlok in blok.blocks"
          :key="childBlok._uid"
          :blok="childBlok"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { SbFormSectionStoryblok } from '@/types'

interface Props {
  blok: SbFormSectionStoryblok
}

const props = defineProps<Props>()
const paddingBottom = computed(() => {
  return `pb-${props.blok.padding_bottom}` || 'pb-0'
})
const paddingTop = computed(() => {
  return `pt-${props.blok.padding_top}` || 'pt-0'
})

const backgroundColor = computed(() => {
  if (props.blok.use_gradient) return ''

  return {
    backgroundColor: props.blok.background.color || '#FFFFFF',
  }
})
useHead(() => ({
  script: [
    {
      src: 'https://js.na.chilipiper.com/marketing.js',
      type: 'text/javascript',
    },
  ],
}))
</script>

<style lang="scss" scoped>
.form-wrapper {
  max-width: 490px;
  margin: 0 auto;
  text-align: center;
}
</style>
